body {
  font-family: "Comfortaa", cursive;
  color: #333;
}
#landing-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#landing-figure {
  animation-name: fade-in;
  animation-duration: 1500ms;
}
#landing-figure img {
  max-width: 600px;
}
#landing-text {
  margin-bottom: 0;
  flex: 1 1 300px;
  animation-name: fade-in;
  animation-duration: 1500ms;
  text-align: center;
}
#start-game-button {
  background-color: rgb(56, 121, 163);
  border-top: 2px solid rgb(17, 47, 71);
  text-align: center;
  padding: 20px 0;
  color: white;
  font-weight: 800;
  width: 100vw;
  animation-name: fade-in;
  animation-duration: 2500ms;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
}
#start-game-button:hover {
  color: silver;
  cursor: pointer;
  user-select: none;
}
#game-header {
  margin-bottom: 0;
}
#game-description {
  margin-top: 10px;
}
#challenge-header {
  margin-top: 40px;
  color: rgb(56, 121, 163);
}
header {
  flex: 1;
  border-bottom: 1px dashed #ccc;
}
.App {
  display: flex;
  height: 100vh;
  text-align: center;
}
.page-button {
  text-decoration: none;
  display: inline-block;
  padding: 20px;
  background-color: rgb(56, 121, 163);
  color: white;
  border-radius: 5px;
  font-size: 0.8em;
  flex: 1;
}
.page-button:hover {
  background-color: #4e4d5c;
}
.page-button:nth-child(2) {
  margin-left: 10px;
}
aside {
  flex: 1;
  background-color: #fefefe;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 3px solid rgb(56, 121, 163);
}
textarea {
  flex: 0 0 200px;
  height: 100px;
  max-height: 200px;
  min-height: 100px;
  max-width: 100%;
  resize: none;
  display: block;
  border-color: rgb(56, 121, 163);
  border-width: 3px;
  border-radius: 15px;
  font-size: 1.2em;
  font-family: monospace;
  margin-bottom: 20px;
  padding: 10px;
}
textarea:focus {
  border: 3px solid #888;
  outline: none;
}
.playground {
  flex: 3;
  background-color: rgb(189, 237, 239);
  display: flex;
  position: relative;
}
div.game-img {
  width: 100px;
  height: 100px;
  z-index: 100;
  background: url("https://res.cloudinary.com/mkf/image/upload/v1686003313/mru-white_learui.png");
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: center;
}
.flex-container {
  display: flex;
  flex: 1;
}
.button-holder {
  display: flex;
}
.hole {
  width: 100px;
  height: 100px;
  background: url("https://res.cloudinary.com/mkf/image/upload/v1686001535/lilypad-green_l05icq.svg")
    center center;
  background-size: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hole-container {
  display: flex;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.hole-container-first {
  justify-content: flex-end;
}
.hole-container-second {
  justify-content: center;
  align-items: center;
}
.hole-container-third {
  height: 100vh;
  flex-direction: column;
  justify-content: flex-end;
}
.hole-container-fourth {
  justify-content: center;
}
.hole-container-fifth {
  justify-content: space-around;
}
.hole-container-sixth {
  justify-content: space-between;
}
.hole-container-seventh {
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.hole-container-eighth {
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
}
.hole-container-ninth {
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  align-items: center;
}
.hole-container-tenth {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100vh;
}
.game-instructions {
  line-height: 2em;
  font-size: 0.8em;
}
.hint {
  margin-top: -10px;
  font-size: 0.8em;
}
code {
  background-color: #ddd;
  padding: 2px 4px;
}
